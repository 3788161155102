import { useEffect, useState } from 'react';
import axios from '../../Configuracoes/axiosConfig';

export const TotalLotes = (url, tipo_cafe) => {
   const [totais, setTotais] = useState({ sacas:0, quilos: 0});

   useEffect(() => {
    const fetchTotais = async () => {
        try {
            const response = await axios.get(url);
            setTotais(response.data);

        } catch (error) {
            console.error(`Erro ao buscar o total dos lotes de ${tipo_cafe} existentes: `, error);
        }
    };

    fetchTotais(); // Executa a função fetchTotais ao montar o componente
   }, [url, tipo_cafe]); // Reexecuta o efeito se url ou tipo_cafe mudarem

  return totais; // Retorna os valores totais

};